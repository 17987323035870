// import

@import "libs/mixins/myMixin";
@import "libs/resets/sanitize";

/* ================================================== *
/*
/*    BASE
/*
/* ================================================== */

body {
  font-family: "游ゴシック Medium", "YuGothic,YuGothicM", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.8;
  color: #3f3929;
  background: url('/assets/images/bg_type1_1.png') 0 0 repeat;
  @include min-sm {
    min-width: 1000px;
  }
}

a {
  color: #3f3929;
  text-decoration: none;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  &:hover {
    opacity: 0.5;
  }
  &:visited {
    color: #3f3929;
  }
}

*:focus {
  outline: none !important;
}

/* ================================================== *
/*
/*    LAYOUT
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        main1
/* ------------------------------------------------- */

.main1 {
  margin-top: 70px;
  @include min-sm {
    margin-top: 100px;
    min-width: 1100px;
  }
  &.-type1 {
    padding-bottom: 90px;
    @include min-sm {
      background: url('/assets/images/img_heading1_1.jpg') center 0 no-repeat;
      background-size: contain;
    }
  }
  .main1__inner1 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    @include min-sm {
      flex-direction: row;
      display: block;
    }
    @at-root {
      body.-top .main1__inner1 {
        padding: 0 0 75px  0;
        @include min-sm {
          padding-bottom: 0;
        }
      }
    }
    @include min-sm {
      padding-bottom: 0;
    }
    @at-root {
      .main1.-type1 .main1__inner1 {
        display: flex;
        justify-content: space-between;
        @include min-sm {
          max-width: 1100px;
          margin: auto;
          padding: 50px;
          border-radius: 10px;
          background: url('/assets/images/bg_type1_1.png') 0 0 repeat;
        }
      }
    }
  }
  .main1__inner1__lnav1 {
    margin: 50px 0 0 0;
    @include min-sm {
      min-width: 250px;
      max-width: 250px;
      margin: 0;
    }
  }
  .main1__inner1__contents1 {
    width: 100%;
    @include min-sm {
      min-width: 710px;
      max-width: 710px;
    }
  }
}

/* ================================================== *
/*
/*    GLOBAL HELPER
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        clearfix
/* ------------------------------------------------- */

.-clearFix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* ------------------------------------------------- *
/*        display
/* ------------------------------------------------- */

.-pcOnly {
  display: none !important;
  @include min-sm {
    display: block !important;
  }
}

.-spOnly {
  display: block !important;
  @include min-sm {
    display: none !important;
  }
}

/* ================================================== *
/*
/*    TYPOGRAPHY
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        title1
/* ------------------------------------------------- */

.title1 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #3f3929;
  @include min-sm {
    font-size: 30px;
    line-height: 1;
  }
  span {
    font-size: 10px;
    display: block;
    font-weight: normal;
    position: relative;
    padding: 5px 0 0 0;
    @include min-sm {
      padding: 15px 0 0 0;
      margin: 15px 0 0 0;
    }
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      background: #fbbf88;
      border-radius: 3px;
    }
  }
}

/* ------------------------------------------------- *
/*        title2
/* ------------------------------------------------- */

.title2 {
  font-size: 15px;
  font-weight: bold;
  color: #2DB0C2;
  background: #fff;
  line-height: 1;
  padding: 30px;
  position: relative;
  border: 1px solid #2DB0C2;
  @include min-sm {
    text-align: center;
    font-size: 30px;
    line-height: 1;
    border: none;
    border-bottom: 1px solid #2DB0C2;
    padding: 0 0 20px 0;
    background: none;
  }
  &:before {
    position: relative;
    top: -1px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 15px;
    background: url('../images/icon_nav5_1.png') 0 0 no-repeat;
    background-size: 19px 15px;
    margin: 0 10px 0 0;
    @include min-sm {
      display: none;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    border-right: 2px solid #2db0c2;
    border-bottom: 2px solid #2db0c2;
    transform: rotate(45deg);
    @include min-sm {
      display: none;
    }
  }
}

/* ------------------------------------------------- *
/*        title3
/* ------------------------------------------------- */

.title3 {
  @include min-sm {
    font-size: 15px;
    font-weight: bold;
    color: #fbc088;
    border-bottom: 1px solid #fbc088;
    padding: 0 0 5px 0;
  }
}

/* ------------------------------------------------- *
/*        title4
/* ------------------------------------------------- */

.title4 {
  margin: 20px 0 0 0;
  @include min-sm {
    margin: 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #fbc088;
  }
  &.-type1 {
    @include min-sm {
      border-color: #fbc088;
    }
  }
  .title4__title1 {
    text-align: center;
  }
  .title4__mainLabel1 {
    font-size: 14px;
    text-align: center;
    color: #fbc088;
    font-weight: bold;
    @at-root {
      .title4.-type1 .title4__mainLabel1 {
        color: #fbc088;
      }
    }
    @include min-sm {
      font-size: 30px;
    }
  }
}

/* ------------------------------------------------- *
/*        title5
/* ------------------------------------------------- */

.title5 {
  background: #F0F0F0;
  padding: 10px;
  .title5__title1 {
    border-left: 5px solid #2DB0C2;
    padding: 0 0 0 10px;
  }
  .title5__mainLabel1 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    color: #2DB0C2;
    @include min-sm {
      font-size: 20px;
    }
  }
}

/* ------------------------------------------------- *
/*        title6
/* ------------------------------------------------- */

.title6 {
  margin: 25px 10px 0;
  @include min-sm {
    margin: 0;
  }
  .title6__date1 {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 0 10px 0 0;
    position: relative;
    top: 1px;
  }
  .title6__title1 {
    font-weight: bold;
    color: #fbc088;
    background: #fff;
    padding: 10px;
    margin: 10px 0 0 0;
    @include min-sm {
      padding: 0;
      background: none;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #fbc088;
    }
  }
  .title6__mainLabel1 {
    font-size: 18px;
    line-height: 1.2;
    @include min-sm {
      font-size: 30px;
    }
  }
}

/* ------------------------------------------------- *
/*        title6-1
/* ------------------------------------------------- */

.title6-1 {
  margin: 25px 10px 0;
  @include min-sm {
    margin: 0;
  }
  .title6-1__date1 {
    font-size: 13px;
    color: #000;
  }
  .title6-1__title1 {
    font-weight: bold;
    color: #fbc088;
    margin: 0 0 20px 0;
    text-align: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #fbc088;
    @include min-sm {
      padding: 0;
      background: none;
      padding: 0 0 20px 0;
      text-align: left;
    }
  }
  .title6-1__mainLabel1 {
    font-size: 24px;
    line-height: 1.2;
    @include min-sm {
      font-size: 30px;
    }
  }
  .title6-1__category {
    font-size: 13px;
    display: block;
    a {
      color: #0D4FED;
      text-decoration: underline;
    }
  }
}

/* ------------------------------------------------- *
/*        title7
/* ------------------------------------------------- */

.title7 {
  font-size: 27px;
  border-bottom: 1px solid #3F3929;
  padding: 0 0 10px 0;
  line-height: 1.2;
}

/* ------------------------------------------------- *
/*        heading1
/* ------------------------------------------------- */

.heading1 {
  background: url('/assets/images/img_heading1_1.jpg') 0 0 no-repeat;
  background-size: cover;
  @include min-sm {
    background: none;
  }
  .heading1__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
    color: #000;
    @include min-sm {
      font-size: 40px;
      padding: 70px 0;
    }
  }
}

/* ------------------------------------------------- *
/*        textLink1
/* ------------------------------------------------- */

a.textLink1 {
  font-size: 14px;
  display: block;
  @include min-sm {
    display: flex;
    align-items: center;
  }
  .label1 {
    margin: 0 10px;
  }
  .textLink1__title1 {
    display: block;
    margin: 5px 0 0 0;
  }
}

/* ------------------------------------------------- *
/*        textLink1-1
/* ------------------------------------------------- */

a.textLink1-1 {
  font-size: 14px;
  display: block;
  @include min-sm {
    display: flex;
  }
  .textLink1-1__title1 {
    display: block;
    margin: 5px 0 0 0;
    @include min-sm {
      color: #0D4FED;
      text-decoration: underline;
      margin: 0 0 0 10px;
    }
  }
  .label1 {
    margin: 0 0 0 10px;
    @include min-sm {
      margin-top: 2px;
    }
  }
}

/* ------------------------------------------------- *
/*        textLink2
/* ------------------------------------------------- */

a.textLink2 {
  @include min-sm {
    color: #2DB0C2;
    font-size: 16px;
    display: block;
    text-decoration: underline;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 0 0;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left: 7px solid #2DB0C2;
    }
  }
}

/* ------------------------------------------------- *
/*        textLink3
/* ------------------------------------------------- */

a.textLink3 {
  @include min-sm {
    display: flex;
    font-size: 13px;
    line-height: 1.2;
    &:before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
      margin: 3px 5px 0 0;
    }
  }
}

/* ------------------------------------------------- *
/*        textLink4
/* ------------------------------------------------- */

a.textLink4 {
  @include min-sm {
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
  }
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    background: url('../images/icon_blank1_1.png') 0 0 no-repeat;
    margin: 0 0 0 5px;
  }
}

/* ------------------------------------------------- *
/*        textLink5
/* ------------------------------------------------- */

a.textLink5 {
  @include min-sm {
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
    white-space: nowrap;
  }
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin: 0 0 0 5px;
  }
}

/* ------------------------------------------------- *
/*        textLinkGroup1
/* ------------------------------------------------- */

.textLinkGroup1 {
  list-style: none;
  padding: 20px;
  margin: 0;
  border: 1px solid #D2D2D2;
  background: #fff;
  li {
    border-top: 1px solid #D2D2D2;
    margin: 15px 0 0 0;
    padding: 15px 0 0 0;
    &:first-child {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        textLinkGroup1-1
/* ------------------------------------------------- */

.textLinkGroup1-1 {
  list-style: none;
  margin: 0 10px;
  border-bottom: 1px dashed #D2D2D2;
  @include min-sm {
    margin: 60px 0 0 0;
  }
  li {
    border-top: 1px dashed #D2D2D2;
    margin: 15px 0 0 0;
    padding: 15px 0 0 0;
    &:first-child {
      @include min-sm {
        padding-top: 0;
        border: none;
        margin: 0;
      }
    }
    &:last-child {
      padding-bottom: 15px;
    }
  }
}

/* ------------------------------------------------- *
/*        textLinkGroup2
/* ------------------------------------------------- */

.textLinkGroup2 {
  .textLinkGroup2__lists1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 5px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        textLinkGroup3
/* ------------------------------------------------- */

.textLinkGroup3 {
  .textLinkGroup3__lists1 {
    list-style: none;
    margin: 0;
    padding: 0;
    @include min-sm {
      display: flex;
      flex-wrap: wrap;
    }
  }
  li {
    @include min-sm {
      width: 50%;
      margin: 0;
    }
  }
  &.-type1 {
    .textLinkGroup3__lists {
      @include min-sm {
        display: block;
      }
    }
    li {
      @include min-sm {
        width: 100%;
      }
    }
  }
}

/* ================================================== *
/*
/*    FRAME
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        frame1
/* ------------------------------------------------- */

.frame1 {
  background-color: #F0F0F0;
}
.frame1__inner1 {
  padding: 20px;
  @include min-sm {
    padding: 80px 0;
    max-width: 1000px;
    margin: auto;
  }
}

/* ================================================== *
/*
/*    LABEL
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        label1
/* ------------------------------------------------- */

.label1 {
  font-size: 10px;
  display: inline-block;
  font-weight: bold;
  background-color: #fbc088;
  text-align: center;
  color: #FFF;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  min-width: 90px;
  height: 20px;
  line-height: 20px;
  &.-type1 {
    background-color: #fbc088;
  }
  &.-type2 {
    background-color: #fbc088;
  }
  &.-type3 {
    background-color: #fbc088;
  }
}

/* ================================================== *
/*
/*    MEDIA
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        media1
/* ------------------------------------------------- */

.media1 {
  display: block;
  border: 1px solid #2DB0C2;
  padding: 15px;
  @include min-sm {
    display: flex;
    padding: 5px;
  }
  .media1__image1 {
    display: none;
    @include min-sm {
      display: block;
    }
  }
  .media1__body1 {
    display: flex;
    align-items: center;
    width: 100%;
    @include min-sm {
      display: block;
      margin: 0 0 0 10px;
    }
  }
  .media1__label1 {
    display: inline-block;
    color: #fff;
    text-align: center;
    background: #2DB0C2;
    padding: 5px 10px;
    line-height: 1;
    margin: 0 10px 0 0;
    @include min-sm {
      font-size: 12px;
      text-align: left;
    }
  }
  .media1__name1 {
    @include min-sm {
      display: block;
      margin: 15px 0 0 0;
    }
  }
  .media1__name1__text1 {
    font-size: 10px;
    font-size: 14px;
    line-height: 1.2;
    color: #0D4FED;
    text-decoration: underline;
    @include min-sm {
      display: block;
      text-decoration: none;
      color: #000;
      text-decoration: none;
      padding: 0 0 5px 0;
      border-bottom: 1px solid #2db0c2;
    }
  }
  .media1__name1__text2 {
    font-size: 10px;
    @include min-sm {
      display: block;
    }
    &:before {
      content: ' / ';
      @include min-sm {
        display: none;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        media2
/* ------------------------------------------------- */

.media2 {
  display: flex;
  .media2__image1 {
    min-width: 107px;
    max-width: 107px;
    margin: 0 10px 0 0;
    @include min-sm {
      min-width: 150px;
      max-width: 150px;
      margin: 0 20px 0 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .media2__body1 {
    width: 100%;
    margin: -10px 0 0 0;
  }
  .media2__date1 {
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    @include min-sm {
      font-size: 14px;
    }
  }
  .media2__title1 {
    font-size: 15px;
    color: #FBC088;
    font-weight: bold;
    line-height: 1.2;
    @include min-sm {
      font-size: 24px;
    }
  }
  .media2__text1 {
    font-size: 13px;
    @include min-sm {
      font-size: 16px;
    }
  }
}

/* ------------------------------------------------- *
/*        mediaGroup1
/* ------------------------------------------------- */

.mediaGroup1 {
  .mediaGroup1__lists {
    list-style: none;
    margin: 0;
    padding: 0;
    @include min-sm {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }
    li {
      margin: 10px 0 0 0;
      @include min-sm {
        width: 33.333%;
        padding: 0 5px 0 5px;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        mediaGroup2
/* ------------------------------------------------- */

.mediaGroup2 {
  margin: 20px 10px 0;
  @include min-sm {
    margin-right: 0;
    margin-left: 0;
  }
  .mediaGroup2__lists {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #FBC088;
    @include min-sm {
      border: none;
    }
    li {
      padding: 20px 0;
      border-bottom: 1px dotted #d2d2d2;
      &:first-child {
        @include min-sm {
          padding-top: 0;
        }
      }
    }
  }
}

/* ================================================== *
/*
/*    FORM
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        select1
/* ------------------------------------------------- */

.select1 {
  border: 1px solid #000;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  line-height: 35px;
  height: 35px;
  background: #fff;
}

/* ------------------------------------------------- *
/*        formGroup1
/* ------------------------------------------------- */

.formGroup1 {
  position: relative;
  @include min-sm {
    display: flex;
  }
  .formGroup1__unit1 {
    background: #C1EAF1;
    padding: 30px 40px;
    margin: 1px 0 0 0;
    @include min-sm {
      width: 50%;
      padding: 20px;
      margin: 0;
      padding: 20px 40px;
    }
    &:first-child {
      padding-top: 20px;
      @include min-sm {
        padding: 20px 40px;
        border-right: 1px solid #fff;
      }
    }
  }
  .formGroup1__label1 {
    color: #2DB0C2;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 5px 0;
  }
  .formGroup1__text1 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 37px;
    height: 37px;
    &:before {
      display: block;
      content: '';
      background: #fff;
      text-align: center;
      width: 37px;
      height: 37px;
      transform: rotate(45deg);
    }
    span {
      font-weight: bold;
      font-size: 16px;
      color: #2DB0C2;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 37px;
      height: 37px;
      text-align: center;
      line-height: 37px;
    }
  }
}

/* ================================================== *
/*
/*    TABLE
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        table1
/* ------------------------------------------------- */

.table1 {
  .table1__header1 {
    position: relative;
  }
  .table1__header1__month {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #000;
    line-height: 1;
    padding: 0 0 10px 0;
  }
  .table1__header1__month__prev {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0 40px 0 0;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid #0d4fed;
      border-left: 2px solid #0d4fed;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 4px;
      right: 0;
      margin: auto;
    }
  }
  .table1__header1__month__next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0 0 0 40px;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid #0d4fed;
      border-right: 2px solid #0d4fed;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 4px;
      margin: auto;
    }
  }
  .table1__header1__date {
    font-size: 13px;
    text-align: center;
    margin: 10px 0 0 0;
  }
  .table1__header1__nav1 {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: absolute;
      bottom: 0;
      &:first-child {
        left: 0;
        a {
          padding: 0 0 0 30px;
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: #0D4FED;
            border-radius: 100%;
          }
          &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            margin: auto;
          }
        }
      }
      &:last-child {
        right: 0;
        a {
          padding: 0 30px 0 0;
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: #0D4FED;
            border-radius: 100%;
          }
          &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto;
          }
        }
      }
    }
    a {
      position: relative;
      color: #0D4FED;
      text-decoration: underline;
    }
  }
  .table1__text1 {
    font-size: 15px;
    text-align: center;
    line-height: 1.2;
    margin: 25px 0 0 0;
    @include min-sm {
      text-align: right;
    }
    strong {
      color: #F00;
    }
    br {
      @include min-sm {
        display: none;
      }
    }
  }
  .table1__today1 {
    @include min-sm {
      display: none;
    }
  }
  .table1__today1__title1 {
    border: 1px solid #FF6464;
    background: #fff;
    font-size: 12px;
    color: #FF6464;
    text-align: center;
    line-height: 1;
    font-weight: bold;
    padding: 5px;
  }
  .table1__today1__week1 {
    background: #FF6464;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .table1__today1__week1__nav1 {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:first-child {
        a {
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            margin: auto;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: #fff;
            border-radius: 100%;
          }
          &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #ff6464;
            border-left: 2px solid #ff6464;
            transform: rotate(-45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            margin: auto;
          }
        }
      }
      &:last-child {
        a {
          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: #fff;
            border-radius: 100%;
          }
          &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #ff6464;
            border-right: 2px solid #ff6464;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
          }
        }
      }
    }
  }
  .table1__today2 {
    margin: 25px 0 0 0;
    position: relative;
    height: 40px;
    display: none;
    @include min-sm {
      display: block;
    }
    span {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 90px;
      color: #FFF;
      line-height: 40px;
      height: 40px;
      display: block;
      background: #FF6464;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      border-radius: 10px 10px 0 0px;
      border: 1px solid #C8C8C8;
      border-bottom: none;
      @at-root {
        .table1__today2 {
          &.-monday {
            span {
              right: 90*5px;
            }
          }
          &.-tuesday {
            span {
              right: 90*4px;
            }
          }
          &.-wednesday {
            span {
              right: 90*3px;
            }
          }
          &.-thursday {
            span {
              right: 90*2px;
            }
          }
          &.-friday {
            span {
              right: 90*1px;
            }
          }
          &.-saturday {
            span {
              right: 0;
            }
          }
        }
      }
    }
  }
  .table1__table1 {
    display: none;
    @include min-sm {
      display: block;
    }
    table {
      width: 100%;
      th,
      td {
        font-size: 14px;
        line-height: 1.2;
        border: 1px solid #C8C8C8;
        padding: 10px 0;
      }
    }
    thead {
      tr {
        background: #C1EAF1;
        color: #377EB7;
        &:first-child {
          th {
            background: #2db0c2;
            color: #fff;
            &:first-child {
              background: #646464;
            }
          }
        }
        th {
          &.-active {
            background: #FF6464;
            color: #fff;
          }
        }
      }
    }
    tbody {
      text-align: center;
      tr {
        th {
          padding-left: 10px;
          padding-right: 10px;
          min-width: 85px;
          &.-type {
            color: #fff;
            background: #646464;
          }
        }
        td {
          &:nth-of-type(1) {
            min-width: 82px;
          }
        }
      }
      td {
        min-width: 90px;
        max-width: 90px;
        width: 90px;
        &.-blank {
          background: #A0A0A0;
        }
        &.-am {
          background: #F0F0F0;
        }
        &.-pm {
          background: #DCDCDC;
        }
        &.-today {
          background: #FFE6E6;
        }
      }
      p {
        color: #000;
        font-size: 13px;
        font-weight: bold;
        margin: 10px 0 0 0;
        &:first-child {
          margin-top: 0;
        }
        &.-change {
          color: #f00;
        }
        span {
          display: block;
          font-size: 10px;
          font-weight: normal;
          margin: 3px 0 0 0;
        }
      }
    }
  }
  .table1__table2 {
    @include min-sm {
      display: none;
    }
    table {
      width: 100%;
      th,
      td {
        font-size: 14px;
        line-height: 1.2;
        border: 1px solid #C8C8C8;
        padding: 10px 0;
      }
    }
    thead {
      tr {
        background: #C1EAF1;
        color: #377EB7;
        &:first-child {
          th {
            background: #c1eaf1;
            color: #2db0c2;
            &:first-child {
              background: #646464;
            }
          }
        }
      }
    }
    tbody {
      text-align: center;
      tr {
        th {
          padding-left: 10px;
          padding-right: 10px;
          &.-type {
            color: #fff;
            background: #646464;
          }
        }
      }
      td {
        &.-blank {
          background: #A0A0A0;
        }
        &.-am {
          background: #F0F0F0;
        }
        &.-pm {
          background: #DCDCDC;
        }
      }
      p {
        color: #000;
        font-size: 13px;
        font-weight: bold;
        margin: 10px 0 0 0;
        &:first-child {
          margin-top: 0;
        }
        &.-change {
          color: #f00;
        }
        span {
          display: block;
          font-size: 10px;
          font-weight: normal;
          margin: 3px 0 0 0;
        }
      }
    }
  }
  .table1__table3 {
    table {
      width: 100%;
      th,
      td {
        font-size: 10px;
        line-height: 1.2;
        border: 1px solid #C8C8C8;
        padding: 10px 0;
        word-break: break-all;
        text-align: center;
        @include min-sm {
          font-size: 15px;
        }
      }
    }
    thead {
      tr {
        font-size: 12px;
        background: #2db0c2;
        color: #fff;
        @include min-sm {
          font-size: 14px;
        }
        &:first-child {
          th {
            &:first-child {
              background: #646464;
            }
          }
        }
      }
    }
    tbody {
      text-align: center;
      @at-root {
        .table1__table3 tbody tr {
          &:nth-of-type(even) {
            th ,
            td {
              border-bottom: 2px solid #C8C8C8;
            }
          }
        }
      }
      tr {
        th {
          padding-left: 10px;
          padding-right: 10px;
          &.-am {
            background: #F0F0F0;
          }
          &.-pm {
            background: #DCDCDC;
          }
        }
      }
      td {
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        min-height: 90px;
        height: 90px;
        vertical-align: top;
        padding-left: 5px;
        padding-right: 5px;
        max-width: 53px;
        min-width: 53px;
        width: 53px;
        @include min-sm {
          text-align: left;
          max-width: 104px;
          min-width: 104px;
          width: 104px;
        }
        &.-today {
          background: #FFE6E6;
        }
      }
      p {
        color: #000;
        font-size: 10px;
        font-weight: bold;
        margin: 10px 0 0 0;
        @include min-sm {
          text-align: center;
          font-size: 15px;
        }
        &:first-child {
          margin-top: 0;
        }
        @at-root {
          .table1__table3 {
            tbody tr {
              &:nth-of-type(odd) {
                p {
                  &:first-child {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
        &.-change {
          color: #f00;
        }
        span {
          display: block;
          font-size: 10px;
          font-weight: normal;
          margin: 3px 0 0 0;
          br {
            @include min-sm {
              display: none;
            }
          }
        }
      }
    }
  }
}

/* ================================================== *
/*
/*    BANNER
/*
/* ================================================== */

.banner1 {
  margin: 40px 0 0 0;
  a {
    @include min-sm {
      display: block;
      margin: 100px 0 0 0;
      padding: 33.5% 0 0 0;
      background: url('/assets/images/banner_type1_2.jpg') 0 0 no-repeat;
      background-size: cover;
    }
  }
  img {
    width: 100%;
    height: auto;
    @include min-sm {
      display: none;
    }
  }
}

/* ================================================== *
/*
/*    BUTTON
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        button1
/* ------------------------------------------------- */

a.button1 {
  background-color: #c1eaf1;
  font-size: 14px;
  font-weight: bold;
  color: #2DB0C2;
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 80px 15px 15px 15px;
  line-height: 1.2;
  @include min-sm {
    font-size: 20px;
    padding-top: 110px;
  }
  &:before {
    content: '';
    display: block;
    left: 0;
    right: 0;
    margin: auto;
  }
  &.-type1 {
    &:before {
      width: 42px;
      height: 42px;
      background: url('../images/icon_nav2_1.png') 0 0 no-repeat;
      background-size: 42px auto;
      position: absolute;
      top: 20px;
      @include min-sm {
        top: 25px;
        width: 56px;
        height: 56px;
        background-size: 56px 56px;
      }
    }
  }
  &.-type2 {
    &:before {
      width: 36px;
      height: 46px;
      background: url('../images/icon_nav2_2.png') 0 0 no-repeat;
      background-size: 42px auto;
      position: absolute;
      top: 17px;
      @include min-sm {
        top: 23px;
        width: 49px;
        height: 61px;
        background-size: 49px 61px;
      }
    }
  }
  &.-type3 {
    @include min-sm {
      padding-top: 90px;
    }
    &:before {
      width: 42px;
      height: 38px;
      background: url('../images/icon_nav2_3.png') 0 0 no-repeat;
      background-size: 42px auto;
      position: absolute;
      top: 20px;
      @include min-sm {
        top: 28px;
        width: 56px;
        height: 50px;
        background-size: 56px 50px;
      }
    }
  }
  &.-type4 {
    &:before {
      width: 42px;
      height: 28px;
      background: url('../images/icon_nav2_4.png') 0 0 no-repeat;
      background-size: 42px auto;
      position: absolute;
      top: 26px;
      @include min-sm {
        top: 35px;
        width: 56px;
        height: 38px;
        background-size: 56px 38px;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        button2
/* ------------------------------------------------- */

a.button2 {
  border-top: 1px solid #fbc088;
  border-bottom: 1px solid #fbc088;
  display: block;
  text-align: center;
  font-size: 14px;
  padding: 20px;
  line-height: 1.2;
  background: #fff;
  color: #FBC088;
  @include min-sm {
    border: none;
    color: #3F3929;
    padding: 83.6% 0 0 0;
  }
  &>span {
    display: inline-block;
    text-align: left;
    position: relative;
    padding: 0 0 0 40px;
    @include min-sm {
      display: none;
    }
    &:before {
      position: relative;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      @include min-sm {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        bottom: auto;
        margin: auto;
      }
    }
    &:after {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 70px;
      width: 225px;
      height: 5px;
      content: '';
      margin: auto;
      @include min-sm {
        display: block;
      }
      @at-root {
        a.button2 {
          &.-type1 span {
            &:after {
              background: #efa864;
            }
          }
          &.-type2 span {
            &:after {
              background: #92cd77;
            }
          }
          &.-type3 span {
            &:after {
              background: #5386bd;
            }
          }
        }
      }
    }
  }
  &.-type1 {
    @include min-sm {
      background: url('../images/img_nav3_1.jpg') center center no-repeat;
      background-size: cover;
    }
    span {
      &:before {
        width: 25px;
        height: 27px;
        background: url('../images/icon_nav3_1.png') 0 0 no-repeat;
        background-size: 25px 27px;
        @include min-sm {
          width: 31px;
          height: 33px;
          background: url('../images/icon_nav4_1.png') 0 0 no-repeat;
          background-size: 31px 33px;
        }
      }
    }
    br {
      display: none;
      @include min-sm {
        display: block;
      }
    }
  }
  &.-type2 {
    @include min-sm {
      background: url('../images/img_nav3_2.jpg') center center no-repeat;
      background-size: cover;
    }
    span {
      &:before {
        width: 29px;
        height: 24px;
        background: url('../images/icon_nav3_2.png') 0 0 no-repeat;
        background-size: 29px 24px;
        @include min-sm {
          width: 50px;
          height: 41px;
          background: url('../images/icon_nav4_2.png') 0 0 no-repeat;
          background-size: 50px 41px;
        }
      }
    }
    br {
      display: none;
      @include min-sm {
        display: block;
      }
    }
  }
  &.-type3 {
    @include min-sm {
      background: url('../images/img_nav3_3.jpg') center center no-repeat;
      background-size: cover;
    }
    span {
      &:before {
        width: 25px;
        height: 25px;
        background: url('../images/icon_nav3_3.png') 0 0 no-repeat;
        background-size: 25px 25px;
        @include min-sm {
          width: 38px;
          height: 37px;
          background: url('../images/icon_nav4_3.png') 0 0 no-repeat;
          background-size: 38px 37px;
        }
      }
    }
  }
  &.-type1 ,
  &.-type2 ,
  &.-type3 {
    .button2__btnMore1 {
      display: none;
      @include min-sm {
        position: relative;
        display: block;
        font-size: 14px;
        text-align: center;
        line-height: 1;
        width: 165px;
        margin: 40px auto 0;
        padding: 8px 10px;
        background: #fff;
        color: #3F3929;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          content: '';
          display: block;
          background: none;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-left: 7px solid #3f3929;
        }
      }
    }
  }
}

/* ------------------------------------------------- *
/*        button3
/* ------------------------------------------------- */

a.button3 {
  display: block;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #2DB0C2;
  padding: 10px;
  line-height: 1.2;
  width: 100%;
  @include min-sm {
    border: none;
    padding: 20px;
  }
  br {
    @include min-sm {
      display: none;
    }
  }
  &>span {
    width: 100%;
    position: relative;
    display: block;
    background: rgba(#FFF,0.9);
    padding: 85px 10px 20px 10px;
    @include min-sm {
      display: block;
      background: rgba(#FFF,0.9);
      width: 100%;
      text-align: center;
      font-size: 20px;
      padding: 90px 0 10px 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      @include min-sm {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  &.-type1 {
    background: url('../images/img_nav6_1.jpg') 0 0 no-repeat;
    background-size: cover;
    span {
      &:before {
        top: 17px;
        width: 33px;
        height: 56px;
        background: url('../images/icon_nav6_1.png') 0 0 no-repeat;
        background-size: 33px 56px;
        @include min-sm {
          top: 11px;
          width: 41px;
          height: 64px;
          background-size: 41px 64px;
        }
      }
    }
  }
  &.-type2 {
    background: url('../images/img_nav6_2.jpg') 0 0 no-repeat;
    background-size: cover;
    span {
      &:before {
        top: 22px;
        width: 43px;
        height: 46px;
        background: url('../images/icon_nav6_2.png') 0 0 no-repeat;
        background-size: 43px 46px;
        @include min-sm {
          width: 53px;
          height: 57px;
          background-size: 53px 57px;
        }
      }
    }
  }
  &.-type3 {
    background: url('../images/img_nav6_3.jpg') 0 0 no-repeat;
    background-size: cover;
    span {
      &:before {
        top: 27px;
        width: 46px;
        height: 29px;
        background: url('../images/icon_nav6_3.png') 0 0 no-repeat;
        background-size: 46px 29px;
        @include min-sm {
          top: 30px;
          width: 51px;
          height: 33px;
          background-size: 51px 33px;
        }
      }
    }
  }
  &.-type4 {
    background: url('../images/img_nav6_4.jpg') 0 0 no-repeat;
    background-size: cover;
    span {
      &:before {
        top: 27px;
        width: 41px;
        height: 37px;
        background: url('../images/icon_nav6_4.png') 0 0 no-repeat;
        background-size: 41px 37px;
        @include min-sm {
          top: 30px;
          width: 46px;
          height: 42px;
          background-size: 46px 42px;
        }
      }
    }
  }
  &.-type1 ,
  &.-type2 ,
  &.-type3 ,
  &.-type4 {
    .button3__btnMore1 {
      display: none;
      @include min-sm {
        position: relative;
        display: block;
        font-size: 14px;
        text-align: center;
        line-height: 1;
        border: 1px solid #2db0c2;
        width: 165px;
        margin: 5px auto 0;
        padding: 5px 10px;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          content: '';
          display: block;
          background: none;
          width: 7px;
          height: 7px;
          border-top: 1px solid #2db0c2;
          border-right: 1px solid #2db0c2;
          transform: rotate(45deg);
        }
      }
    }
  }
}

/* ------------------------------------------------- *
/*        button4
/* ------------------------------------------------- */

a.button4 {
  display: block;
  width: 100%;
  height: 75px;
  background: #fbc088;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 40px 10px 10px 10px;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    display: block;
  }
  &.-type1 {
    &:before {
      top: 14px;
      width: 23px;
      height: 23px;
      background: url('../images/icon_nav7_1.png') 0 0 no-repeat;
      background-size: 23px;
    }
  }
  &.-type2 {
    &:before {
      top: 14px;
      width: 23px;
      height: 23px;
      background: url('../images/icon_nav7_2.png') 0 0 no-repeat;
      background-size: 23px;
    }
  }
  &.-type3 {
    &:before {
      top: 14px;
      width: 23px;
      height: 23px;
      background: url('../images/icon_nav7_3.png') 0 0 no-repeat;
      background-size: 23px;
    }
  }
  &:hover {
    opacity: 1;
    background: darken(#fbc088,10);
  }
}

/* ------------------------------------------------- *
/*        button5
/* ------------------------------------------------- */

a.button5 {
  display: block;
  font-size: 15px;
  line-height: 1.2;
  color: #2DB0C2;
  @include min-sm {
    font-size: 19px;
    background: #FFF;
    border: 1px solid #82c4b8;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6E6E6E;
  }
  br {
    display: none;
    @include min-sm {
      display: block;
    }
  }
  i {
    position: relative;
    @include min-sm {
      padding: 15px;
      width: 60px;
      height: 70px;
    }
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      content: '';
      display: block;
      background: none;
      width: 7px;
      height: 7px;
      border-top: 1px solid #2db0c2;
      border-right: 1px solid #2db0c2;
      transform: rotate(45deg);
      @include min-sm {
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
        display: block;
        transform: none;
      }
    }
    &:after {
      @include min-sm {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -25px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin: auto;
        border: 12px solid transparent;
        border-left: 15px solid transparent;
      }
    }
  }
  span {
    @include min-sm {
      width: 100%;
    }
  }
  &.-type1 {
    @include min-sm {
      border-color: #82c4b8;
    }
    &:hover {
      @include min-sm {
        background: #82c4b8;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #82c4b8;
      }
      &:before {
        @include min-sm {
          width: 30px;
          height: 36px;
          background: url('../images/icon_nav4_1.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #82c4b8;
      }
    }
  }
  &.-type2 {
    @include min-sm {
      border-color: #84aacf;
    }
    &:hover {
      @include min-sm {
        background: #84aacf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #84aacf;
      }
      &:before {
        @include min-sm {
          width: 35px;
          height: 46px;
          background: url('../images/icon_nav4_2.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #84aacf;
      }
    }
  }
  &.-type3 {
    @include min-sm {
      border-color: #a0a3ce;
    }
    &:hover {
      @include min-sm {
        background: #a0a3ce;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #a0a3ce;
      }
      &:before {
        @include min-sm {
          width: 41px;
          height: 33px;
          background: url('../images/icon_nav4_3.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #a0a3ce;
      }
    }
  }
  &.-type4 {
    @include min-sm {
      border-color: #cd9fc4;
    }
    &:hover {
      @include min-sm {
        background: #cd9fc4;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #cd9fc4;
      }
      &:before {
        @include min-sm {
          width: 35px;
          height: 40px;
          background: url('../images/icon_nav4_4.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #cd9fc4;
      }
    }
  }
  &.-type5 {
    @include min-sm {
      border-color: #b9daaf;
    }
    &:hover {
      @include min-sm {
        background: #b9daaf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #b9daaf;
      }
      &:before {
        @include min-sm {
          width: 25px;
          height: 46px;
          background: url('../images/icon_nav4_5.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #b9daaf;
      }
    }
  }
  &.-type6 {
    @include min-sm {
      border-color: #fbbfa3;
    }
    &:hover {
      @include min-sm {
        background: #fbbfa3;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #fbbfa3;
      }
      &:before {
        @include min-sm {
          width: 42px;
          height: 33px;
          background: url('../images/icon_nav4_6.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #fbbfa3;
      }
    }
  }
  &.-type7 {
    @include min-sm {
      border-color: #6bb7d1;
    }
    &:hover {
      @include min-sm {
        background: #6bb7d1;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #6bb7d1;
      }
      &:before {
        @include min-sm {
          width: 43px;
          height: 33px;
          background: url('../images/icon_nav4_7.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #6bb7d1;
      }
    }
  }
  &.-type8 {
    @include min-sm {
      border-color: #e8a1af;
    }
    &:hover {
      @include min-sm {
        background: #e8a1af;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8a1af;
      }
      &:before {
        @include min-sm {
          width: 27px;
          height: 49px;
          background: url('../images/icon_nav4_8.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8a1af;
      }
    }
  }
  &.-type9 {
    @include min-sm {
      border-color: #82c4b8;
    }
    &:hover {
      @include min-sm {
        background: #82c4b8;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #82c4b8;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 36px;
          background: url('../images/icon_nav4_9.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #82c4b8;
      }
    }
  }
  &.-type10 {
    @include min-sm {
      border-color: #84aacf;
    }
    &:hover {
      @include min-sm {
        background: #84aacf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #84aacf;
      }
      &:before {
        @include min-sm {
          width: 42px;
          height: 38px;
          background: url('../images/icon_nav4_10.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #84aacf;
      }
    }
  }
  &.-type11 {
    @include min-sm {
      border-color: #a0a3ce;
    }
    &:hover {
      @include min-sm {
        background: #a0a3ce;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #a0a3ce;
      }
      &:before {
        @include min-sm {
          width: 39px;
          height: 39px;
          background: url('../images/icon_nav4_11.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #a0a3ce;
      }
    }
  }
  &.-type12 {
    @include min-sm {
      border-color: #cd9fc4;
    }
    &:hover {
      @include min-sm {
        background: #cd9fc4;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #cd9fc4;
      }
      &:before {
        @include min-sm {
          width: 34px;
          height: 46px;
          background: url('../images/icon_nav4_12.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #cd9fc4;
      }
    }
  }
  &.-type13 {
    @include min-sm {
      border-color: #b9daaf;
    }
    &:hover {
      @include min-sm {
        background: #b9daaf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #b9daaf;
      }
      &:before {
        @include min-sm {
          width: 31px;
          height: 43px;
          background: url('../images/icon_nav4_13.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #b9daaf;
      }
    }
  }
  &.-type14 {
    @include min-sm {
      border-color: #fbbfa3;
    }
    &:hover {
      @include min-sm {
        background: #fbbfa3;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #fbbfa3;
      }
      &:before {
        @include min-sm {
          width: 36px;
          height: 36px;
          background: url('../images/icon_nav4_14.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #fbbfa3;
      }
    }
  }
  &.-type15 {
    @include min-sm {
      border-color: #6bb7d1;
    }
    &:hover {
      @include min-sm {
        background: #6bb7d1;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #6bb7d1;
      }
      &:before {
        @include min-sm {
          width: 39px;
          height: 31px;
          background: url('../images/icon_nav4_15.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #6bb7d1;
      }
    }
  }
  &.-type16 {
    @include min-sm {
      border-color: #e8a1af;
    }
    &:hover {
      @include min-sm {
        background: #e8a1af;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8a1af;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 40px;
          background: url('../images/icon_nav4_16.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8a1af;
      }
    }
  }
  &.-type17 {
    @include min-sm {
      border-color: #e8c23f;
    }
    &:hover {
      @include min-sm {
        background: #e8c23f;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8c23f;
      }
      &:before {
        @include min-sm {
          width: 36px;
          height: 35px;
          background: url('../images/icon_nav4_17.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8c23f;
      }
    }
  }
  &.-type18 {
    @include min-sm {
      border-color: #82c4b8;
    }
    &:hover {
      @include min-sm {
        background: #82c4b8;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #82c4b8;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 38px;
          background: url('../images/icon_nav4_18.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #82c4b8;
      }
    }
  }
  &.-type19 {
    @include min-sm {
      border-color: #84aacf;
    }
    &:hover {
      @include min-sm {
        background: #84aacf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #84aacf;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 24px;
          background: url('../images/icon_nav4_19.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #84aacf;
      }
    }
  }
  &.-type20 {
    @include min-sm {
      border-color: #a0a3ce;
    }
    &:hover {
      @include min-sm {
        background: #a0a3ce;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #a0a3ce;
      }
      &:before {
        @include min-sm {
          width: 20px;
          height: 40px;
          background: url('../images/icon_nav4_20.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #a0a3ce;
      }
    }
  }
  &.-type21 {
    @include min-sm {
      border-color: #cd9fc4;
    }
    &:hover {
      @include min-sm {
        background: #cd9fc4;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #cd9fc4;
      }
      &:before {
        @include min-sm {
          width: 32px;
          height: 38px;
          background: url('../images/icon_nav4_21.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #cd9fc4;
      }
    }
  }
  &.-type22 {
    @include min-sm {
      border-color: #b9daaf;
    }
    &:hover {
      @include min-sm {
        background: #b9daaf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #b9daaf;
      }
      &:before {
        @include min-sm {
          width: 31px;
          height: 41px;
          background: url('../images/icon_nav4_22.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #b9daaf;
      }
    }
  }
  &.-type23 {
    @include min-sm {
      border-color: #fbbfa3;
    }
    &:hover {
      @include min-sm {
        background: #fbbfa3;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #fbbfa3;
      }
      &:before {
        @include min-sm {
          width: 36px;
          height: 36px;
          background: url('../images/icon_nav4_23.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #fbbfa3;
      }
    }
  }
  &.-type24 {
    @include min-sm {
      border-color: #6bb7d1;
    }
    &:hover {
      @include min-sm {
        background: #6bb7d1;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #6bb7d1;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 36px;
          background: url('../images/icon_nav4_24.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #6bb7d1;
      }
    }
  }
  &.-type25 {
    @include min-sm {
      border-color: #e8a1af;
    }
    &:hover {
      @include min-sm {
        background: #e8a1af;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8a1af;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 40px;
          background: url('../images/icon_nav4_25.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8a1af;
      }
    }
  }
  &.-type26 {
    @include min-sm {
      border-color: #82c4b8;
    }
    &:hover {
      @include min-sm {
        background: #82c4b8;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #82c4b8;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 44px;
          background: url('../images/icon_nav4_26.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #82c4b8;
      }
    }
  }
  &.-type27 {
    @include min-sm {
      border-color: #84aacf;
    }
    &:hover {
      @include min-sm {
        background: #84aacf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #84aacf;
      }
      &:before {
        @include min-sm {
          width: 37px;
          height: 33px;
          background: url('../images/icon_nav4_27.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #84aacf;
      }
    }
  }
  &.-type28 {
    @include min-sm {
      border-color: #a0a3ce;
    }
    &:hover {
      @include min-sm {
        background: #a0a3ce;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #a0a3ce;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 38px;
          background: url('../images/icon_nav4_28.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #a0a3ce;
      }
    }
  }
  &.-type29 {
    @include min-sm {
      border-color: #cd9fc4;
    }
    &:hover {
      @include min-sm {
        background: #cd9fc4;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #cd9fc4;
      }
      &:before {
        @include min-sm {
          width: 25px;
          height: 47px;
          background: url('../images/icon_nav4_29.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #cd9fc4;
      }
    }
  }
  &.-type30 {
    @include min-sm {
      border-color: #b9daaf;
    }
    &:hover {
      @include min-sm {
        background: #b9daaf;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #b9daaf;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 40px;
          background: url('../images/icon_nav4_30.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #b9daaf;
      }
    }
  }
  &.-type31 {
    @include min-sm {
      border-color: #fbbfa3;
    }
    &:hover {
      @include min-sm {
        background: #fbbfa3;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #fbbfa3;
      }
      &:before {
        @include min-sm {
          width: 20px;
          height: 39px;
          background: url('../images/icon_nav4_31.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #fbbfa3;
      }
    }
  }
  &.-type32 {
    @include min-sm {
      border-color: #6bb7d1;
    }
    &:hover {
      @include min-sm {
        background: #6bb7d1;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #6bb7d1;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 38px;
          background: url('../images/icon_nav4_32.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #6bb7d1;
      }
    }
  }
  &.-type33 {
    @include min-sm {
      border-color: #e8a1af;
    }
    &:hover {
      @include min-sm {
        background: #e8a1af;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8a1af;
      }
      &:before {
        @include min-sm {
          width: 38px;
          height: 32px;
          background: url('../images/icon_nav4_33.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8a1af;
      }
    }
  }
  &.-type34 {
    @include min-sm {
      border-color: #e8c23f;
    }
    &:hover {
      @include min-sm {
        background: #e8c23f;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #e8c23f;
      }
      &:before {
        @include min-sm {
          width: 42px;
          height: 45px;
          background: url('../images/icon_nav4_34.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #e8c23f;
      }
    }
  }
  &.-type35 {
    @include min-sm {
      border-color: #82c4b8;
    }
    &:hover {
      @include min-sm {
        background: #82c4b8;
        color: #fff;
        opacity: 1;
      }
    }
    i {
      @include min-sm {
        background: #82c4b8;
      }
      &:before {
        @include min-sm {
          width: 40px;
          height: 45px;
          background: url('../images/icon_nav4_35.png') 0 0 no-repeat;
        }
      }
      &:after {
        border-left-color: #82c4b8;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        button6
/* ------------------------------------------------- */

a.button6 {
  @include min-sm {
    display: block;
    color: #2DB0C2;
    font-size: 14px;
    line-height: 1.2;
    width: 100%;
  }
  &:before {
    @include min-sm {
      content: '';
      display: block;
      width: 100%;
      height: 90px;
    }
  }
  &>span {
    @include min-sm {
      position: relative;
      display: block;
      background: #F0F0F0;
      padding: 18px 5px 0 12px;
      height: 50px;
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        content: '';
        display: block;
        width: 19px;
        height: 19px;
        margin: auto;
        border: 1px solid #2DB0C2;
        border-radius: 100%;
      }
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 23px;
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        margin: auto;
        border-top: 1px solid #2DB0C2;
        border-right: 1px solid #2DB0C2;
        transform: rotate(45deg);
      }
    }
  }
  &.-type1 {
    @include min-sm {
      &:before {
        background: url('../images/img_nav7_1.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.-type2 {
    @include min-sm {
      &:before {
        background: url('../images/img_nav7_2.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.-type3 {
    @include min-sm {
      &:before {
        background: url('../images/img_nav7_3.jpg') center center no-repeat;
        background-size: cover;
      }
    }
    span {
      @include min-sm {
        padding-top: 10px;
      }
    }
  }
  &.-type4 {
    @include min-sm {
      &:before {
        background: url('../images/img_nav7_4.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.-type5 {
    @include min-sm {
      &:before {
        background: url('../images/img_nav7_5.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        button7
/* ------------------------------------------------- */

a.button7 {
  font-size: 14px;
  color: #2DB0C2;
  font-weight: bold;
  text-align: center;
  border: 1px solid #2DB0C2;
  line-height: 1;
  padding: 10px 20px;
}

/* ------------------------------------------------- *
/*        button8
/* ------------------------------------------------- */

a.button8 {
  display: block;
  border: 1px solid #fbc088;
  padding: 5px 10px;
  text-align: center;
  color: #fbc088;
  font-size: 15px;
  position: relative;
  background: #fff;
  @include min-sm {
    font-size: 14px;
  }
  &.-type1 {
    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 1px solid #fbc088;
      border-left: 1px solid #fbc088;
      transform: rotate(-45deg);
    }
  }
  &.-type2 {
    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 1px solid #fbc088;
      border-right: 1px solid #fbc088;
      transform: rotate(45deg);
    }
  }
}

/* ------------------------------------------------- *
/*        buttonGroup1
/* ------------------------------------------------- */

.buttonGroup1 {
  margin: 30px 0 0 0;
  @include min-sm {
    max-width: 1000px;
    margin: 60px auto 0;
  }
  .buttonGroup1__lists1 {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 10px 0;
    padding: 0;
    @include min-sm {
      margin: 0 -22px;
    }
  }
  li {
    display: flex;
    width: 50%;
    padding: 0 10px;
    margin: 20px 0 0 0;
    @include min-sm {
      width: 25%;
      margin: 0;
      padding: 0 22px;
    }
  }
}

/* ------------------------------------------------- *
/*        buttonGroup2
/* ------------------------------------------------- */

.buttonGroup2 {
  margin: 20px 0 0 0;
  @include min-sm {
    max-width: 1000px;
    margin: 60px auto 0;
  }
  .buttonGroup2__lists1 {
    list-style: none;
    padding: 0;
    margin: 25px 0 0 0;
    @include min-sm {
      display: flex;
      margin: 50px -22px 0;
    }
  }
  li {
    margin: 0 0 -1px 0;
    @include min-sm {
      width: 33.333%;
      margin: 0;
      padding: 0 22px;
    }
  }
}

/* ------------------------------------------------- *
/*        buttonGroup3
/* ------------------------------------------------- */

.buttonGroup3 {
  .buttonGroup3__inner1 {
    list-style: none;
    margin: -20px 10px 0 10px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    @include min-sm {
      margin: -20px -22px 0;
    }
  }
  li {
    width: 50%;
    display: flex;
    padding: 0 10px;
    margin: 20px 0 0 0;
    @include min-sm {
      width: 33.333%;
      padding: 0 22px;
    }
    &:first-child {
      @include min-sm {
        display: none;
      }
    }
  }
  a {
    display: flex;
  }
}

/* ------------------------------------------------- *
/*        buttonGroup4
/* ------------------------------------------------- */

.buttonGroup4 {
  .buttonGroup4__inner1 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
  }
  li {
    width: 33.333%;
    padding: 0 0 0 1px;
    &:first-child {
      padding: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        buttonGroup5
/* ------------------------------------------------- */

.buttonGroup5 {
  display: none;
  border: 1px solid #2DB0C2;
  border-top: none;
  overflow: hidden;
  @include min-sm {
    border: none;
    padding: 0;
    display: block !important;
  }
  .buttonGroup5__inner1 {
    list-style: none;
    margin: 0 30px;
    padding: 0;
    &:first-child {
      margin-top: 30px;
      margin-bottom: 10px;
      @include min-sm {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 30px;
      @include min-sm {
        margin-bottom: 0;
      }
    }
    @include min-sm {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -25px;
    }
  }
  li {
    margin: 10px 0 0 0;
    &:first-child {
      margin: 0;
      @include min-sm {
        margin-top: 40px;
      }
    }
    @include min-sm {
      width: 33.333%;
      margin: 40px 0 0 0;
      padding: 0 25px;
    }
  }
}

/* ------------------------------------------------- *
/*        buttonGroup6
/* ------------------------------------------------- */

.buttonGroup6 {
  .buttonGroup6__lists1 {
    list-style: none;
    margin: 0 -20px;
    padding: 0;
    display: flex;
  }
  li {
    @include min-sm {
      width: 20%;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
  }
}

/* ------------------------------------------------- *
/*        tab1
/* ------------------------------------------------- */

.tab1 {
  display: none;
  @include min-sm {
    display: block;
    margin-top: -80px;
    border-bottom: 8px solid #fbc088;
  }
  .tab1__inner1 {
    padding: 0 20px;
    @include min-sm {
      max-width: 1000px;
      margin: auto;
      padding: 0;
    }
  }
  .tab1__lists1 {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 -3px;
    li {
      width: 20%;
      margin: 0 3px;
      a {
        position: relative;
        display: block;
        color: #fbc088;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        font-weight: bold;
        height: 90px;
        padding: 45px 0 15px 0;
        border-radius: 5px 5px 0 0;
        background: #f7f2eb;
        @include min-sm {
          height: 80px;
          padding: 40px 0 25px;
          font-size: 14px;
          line-height: 1.2;
        }
        @at-root {
          .tab1__lists1 li.-active a {
            @include min-sm {
              background: #fbc088;
              color: #fff;
            }
          }
        }
        &:hover {
          opacity: 1;
        }
        &:before {
          position: absolute;
          display: block;
          content: '';
          margin: auto;
          @include min-sm {
          }
        }
      }
      &.-type1 {
        a {
          &:before {
            @include min-sm {
              background: url('../images/icon_nav1_1.png') 0 0 no-repeat;
              top: 8px;
              left: 0;
              right: 0;
              margin: auto;
              width: 25px;
              height: 25px;
              background-size: 25px 25px;
            }
          }
          @at-root {
            .tab1__lists1 li.-active.-type1 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_1_on.png') 0 0 no-repeat;
                  }
                }
              }
            }
          }
        }
      }
      &.-type2 {
        a {
          padding-top: 50px;
          &:before {
            @include min-sm {
              background: url('../images/icon_nav1_2.png') 0 0 no-repeat;
              top: 13px;
              left: 0;
              right: 0;
              margin: auto;
              width: 20px;
              height: 27px;
              background-size: 20px 27px;
            }
          }
          @at-root {
            .tab1__lists1 li.-active.-type2 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_2_on.png') 0 0 no-repeat;
                  }
                }
              }
            }
          }
        }
      }
      &.-type3 {
        a {
          padding-top: 50px;
          &:before {
            @include min-sm {
              background: url('../images/icon_nav1_3.png') 0 0 no-repeat;
              top: 13px;
              left: 0;
              right: 0;
              margin: auto;
              width: 27px;
              height: 27px;
              background-size: 27px 27px;
            }
          }
          @at-root {
            .tab1__lists1 li.-active.-type3 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_3_on.png') 0 0 no-repeat;
                  }
                }
              }
            }
          }
        }
      }
      &.-type4 {
        a {
          padding-top: 50px;
          &:before {
            @include min-sm {
              background: url('../images/icon_nav1_4.png') 0 0 no-repeat;
              top: 13px;
              left: 0;
              right: 0;
              margin: auto;
              width: 26px;
              height: 27px;
              background-size: 26px 27px;
            }
          }
          @at-root {
            .tab1__lists1 li.-active.-type4 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_4_on.png') 0 0 no-repeat;
                  }
                }
              }
            }
          }
        }
      }
      &.-type5 {
        a {
          padding-top: 50px;
          &:before {
            @include min-sm {
              background: url('../images/icon_nav1_5.png') 0 0 no-repeat;
              top: 13px;
              left: 0;
              right: 0;
              margin: auto;
              width: 21px;
              height: 27px;
              background-size: 21px 27px;
            }
          }
          @at-root {
            .tab1__lists1 li.-active.-type5 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_5_on.png') 0 0 no-repeat;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------- *
/*        tab2
/* ------------------------------------------------- */

.tab2 {
  display: none;
  @include min-sm {
    position: relative;
    z-index: 10001;
    margin: -30px 0 0 0;
    border-bottom: 8px solid #fbc088;
  }
  &.-type6 {
    border-color: #3f3929;
  }
  @at-root {
    .header1.-type1 .tab2 {
      @include min-sm {
        display: block;
      }
    }
  }
  .tab2__inner1 {
    max-width: 1000px;
    margin: auto;
    padding: 0;
  }
  .tab2__lists1 {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 -3px;
    width: 1000px;
    li {
      width: 20%;
      margin: 0 3px;
      display: flex;
      a {
        position: relative;
        display: block;
        color: #fbc088;
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        line-height: 1.1;
        font-size: 12px;
        background: #fff;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid #FBC088;
        padding: 7px 10px;
        &:hover {
          opacity: 1;
        }
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px 0 0;
        }
        @at-root {
          .tab2__lists1 li.-active a {
            @include min-sm {
              background: #fbc088;
              color: #fff;
            }
          }
        }
      }
      &.-type1 {
        a {
          &:before {
            background: url('../images/icon_nav1_1.png') 0 0 no-repeat;
            min-width: 17px;
            max-width: 17px;
            height: 17px;
            background-size: 17px 17px;
          }
          @at-root {
            .tab2__lists1 li.-active.-type1 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_1_on.png') 0 0 no-repeat;
                    background-size: 17px 17px;
                  }
                }
              }
            }
          }
        }
      }
      &.-type2 {
        a {
          &:before {
            background: url('../images/icon_nav1_2.png') 0 0 no-repeat;
            min-width: 14px;
            max-width: 14px;
            height: 19px;
            background-size: 14px 19px;
          }
          @at-root {
            .tab2__lists1 li.-active.-type2 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_2_on.png') 0 0 no-repeat;
                    background-size: 14px 19px;
                  }
                }
              }
            }
          }
        }
      }
      &.-type3 {
        a {
          &:before {
            background: url('../images/icon_nav1_3.png') 0 0 no-repeat;
            min-width: 19px;
            max-width: 19px;
            height: 19px;
            background-size: 19px 19px;
          }
          @at-root {
            .tab2__lists1 li.-active.-type3 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_3_on.png') 0 0 no-repeat;
                    background-size: 19px 19px;
                  }
                }
              }
            }
          }
        }
      }
      &.-type4 {
        a {
          &:before {
            background: url('../images/icon_nav1_4.png') 0 0 no-repeat;
            min-width: 18px;
            max-width: 18px;
            height: 19px;
            background-size: 18px 19px;
          }
          @at-root {
            .tab2__lists1 li.-active.-type4 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_4_on.png') 0 0 no-repeat;
                    background-size: 18px 19px;
                  }
                }
              }
            }
          }
        }
      }
      &.-type5 {
        a {
          &:before {
            background: url('../images/icon_nav1_5.png') 0 0 no-repeat;
            min-width: 17px;
            max-width: 17px;
            height: 21px;
            background-size: 17px 21px;
          }
          @at-root {
            .tab2__lists1 li.-active.-type5 {
              a {
                &:before {
                  @include min-sm {
                    background: url('../images/icon_nav1_5_on.png') 0 0 no-repeat;
                    background-size: 17px 21px;
                  }
                }
              }
            }
          }
        }
      }
      &.-type6 {
        a {
          color: #fff;
          background: #3F3929;
          font-size: 11px;
          border-color: #3F3929;
          &:before {
            background: url('../images/icon_nav1_6.png') 0 0 no-repeat;
            min-width: 18px;
            max-width: 18px;
            height: 13px;
            background-size: 18px 13px;
          }
        }
      }
    }
  }
}

/* ================================================== *
/*
/*    BLOCK
/*
/* ================================================== */

/* ------------------------------------------------- *
/*        header1
/* ------------------------------------------------- */

.header1 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  background: url('/assets/images/bg_type1_1.png') 0 0 repeat;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  @include min-sm {
    min-width: 1100px;
  }
  &.-active {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    @include min-sm {
      background: none;
      height: auto;
    }
  }
  &.-hide {
    top: -75px;
    @include min-sm {
      top: -120px;
    }
  }
  .header1__inner1 {
    @at-root {
      .header1.-active .header1__inner1 {
        height: 100%;
        background: #fff;
        overflow: scroll;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        @include min-sm {
          overflow: visible;
        }
      }
    }
    @include min-sm {
      overflow: visible;
    }
  }
  .header1__inner2 {
    position: relative;
    padding: 15px 0;
    height: 70px;
    z-index: 10000;
    @include min-sm {
      max-width: 1000px;
      height: 100px;
      margin: auto;
    }
    @at-root {
      .header1.-active .header1__inner2 {
        background: url('/assets/images/bg_type1_1.png') 0 0 repeat;
      }
    }
  }
  .header1__logo1 {
    text-align: center;
    padding: 3px 0 0 0;
    @include min-sm {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 160px;
      background: #fbc088;
      z-index: 1;
      padding: 10px 0 0 0;
    }
    @at-root {
      .header1.-type1 .header1__logo1 {
        @include min-sm {
          top: 5px;
          background: none;
          width: auto;
          height: auto;
        }
      }
    }
    a {
      @include min-sm {
        display: block;
        &:before {
          content: '';
          display: block;
          width: 118px;
          height: 137px;
          margin: auto;
          background: url('/assets/images/logo_seisyukai1_2.png') 0 0 no-repeat;
          @at-root {
            .header1.-type1 .header1__logo1 a:before {
              width: 306px;
              height: 42px;
              background: url('/assets/images/logo_seisyukai1_4.png') 0 center no-repeat;
              background-size: 280px 42px;
            }
          }
        }
      }
    }
    img {
      width: 185px;
      height: auto;
      @include min-sm {
        display: none;
      }
    }
  }
  .header1__nav1 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    top: 15px;
    right: 370px;
    @include min-sm {
      display: flex;
    }
    li {
      margin: 0 0 0 20px;
      &:first-child {
        margin: 0;
      }
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 0;
        width: 15px;
        height: 14px;
        @at-root {
          .header1__nav1 li {
            &.-type1 {
              &:before {
                background: url('/assets/images/icon_nav8_1.png') 0 0 no-repeat;
              }
            }
            &.-type2 {
              &:before {
                background: url('/assets/images/icon_nav8_2.png') 0 0 no-repeat;
              }
            }
            &.-type3 {
              &:before {
                background: url('/assets/images/icon_nav8_3.png') 0 0 no-repeat;
              }
            }
          }
        }
      }
    }
    a {
      font-size: 14px;
    }
  }
  .header1__contact1 {
    position: absolute;
    top: 15px;
    right: 0;
    display: none;
    @include min-sm {
      display: block;
      text-align: right;
    }
  }
  .header1__contact1__tell1 {
    margin: 0 0 10px 0;
    img {}
  }
  .header1__contact1__button1 {
    display: inline-block;
    vertical-align: middle;
    @at-root {
      .header1.-type1 .header1__contact1__button1 {
        @include min-sm {
          display: none;
        }
      }
    }
    a {
      display: block;
      border-radius: 3px;
      text-align: center;
      font-size: 13px;
      color: #fff;
      font-weight: bold;
      padding: 5px 10px;
      background: #3F3929;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 23px;
        height: 17px;
        background: url('../images/icon_btn1_1.png') 0 0 no-repeat;
        margin: 0 5px 0 0;
      }
    }
  }
  .header1__contact1__text1 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    span {
      font-size: 12px;
      display: block;
      margin: 0 0 3px 0;
    }
  }
  .header1__btnNav1 {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 15px;
    @include min-sm {
      display: none;
    }
    @at-root {
      .header1.-active .header1__btnNav1 {
        background: url('../images/icon_gnav1_2.png') 0 0 no-repeat;
        background-size: 30px;
        width: 30px;
        height: 30px;
        margin: auto;
      }
    }
    img {
      width: 30px;
      height: auto;
      @at-root {
        .header1.-active .header1__btnNav1 img {
          display: none;
        }
      }
    }
  }
  .header1__btnAccess1 {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 15px;
    @include min-sm {
      display: none;
    }
    img {
      width: 34px;
      height: auto;
    }
  }
}

/* ------------------------------------------------- *
/*        nav1
/* ------------------------------------------------- */

.nav1 {
  display: none;
  @at-root {
    .header1.-active .nav1 {
      display: block;
      @include min-sm {
        display: none;
      }
    }
  }
  span,
  a {
    border-top: 1px solid #6E6E6E;
    padding: 20px 30px;
    display: block;
  }
  @include min-sm {
    display: none;
  }
  .nav1__lists1 {
    ul {
      margin: 0 0 0 20px;
      display: none;
    }
    li {
      &>span {
        position: relative;
        font-weight: bold;
        &:before {
          content: '';
          display: block;
          width: 2px;
          height: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 15px;
          margin: auto;
          background: #000;
          @at-root {
            .nav1__lists1>li>a.-active ,
            .nav1__lists1>li>span.-active {
              &:before {
                display: none;
              }
            }
          }
        }
        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 2px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 11px;
          margin: auto;
          background: #000;
        }
      }
    }
  }
  .nav1__lists1__btnClose1 {
    background: #f4f4f4;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    border-top: 1px solid #6E6E6E;
    border-bottom: 1px solid #6E6E6E;
  }
}

/* ------------------------------------------------- *
/*        footer1
/* ------------------------------------------------- */

.footer1 {
  display: none;
  @include min-sm {
    display: block;
    margin: 85px 0 0 0;
  }
  .footer1__inner1 {
    @include min-sm {
      position: relative;
      max-width: 1000px;
      margin: auto;
      padding: 0 0 30px 0;
    }
  }
  .footer1__nav1 {
    @include min-sm {
      display: flex;
    }
  }
  .footer1__catch1 {
    @include min-sm {
      min-width: 250px;
      height: 250px;
      border: 1px solid #fbc088;
      text-align: center;
      line-height: 250px;
      background: #fff;
    }
  }
  .footer1__body1 {
    @include min-sm {
      width: 100%;
      padding: 0 0 0 100px;
    }
  }
  .footer1__inner2 {
    @include min-sm {
      display: flex;
      margin: 40px -20px 0;
      &:nth-of-type(1) {
        margin-top: 20px;
      }
    }
  }
  .footer1__unit1 {
    @include min-sm {
      white-space: nowrap;
      padding: 0 20px;
    }
    &:nth-of-type(1) {
      @include min-sm {
      }
    }
    &:nth-of-type(2) {
      @include min-sm {
      }
    }P
    &:nth-of-type(3) {
      @include min-sm {
      }
    }
  }
  .footer1__banner1 {
    img {
      @include min-sm {
        width: 100%;
        height: auto;
      }
    }
  }
  .footer1__groupSite1 {
    overflow: hidden;
  }
  .footer1__groupSite1__inner1 {
    @include min-sm {
      position: relative;
      background: #fbc088;
      max-width: 1000px;
      width: 1000px;
      margin: auto;
      padding: 20px 0 50px 30px;
      z-index: 2;
    }
    &:before {
      content: '';
      display: block;
      background: #fbc088;
      position: absolute;
      left: 50%;
      top: 0;
      width: 300%;
      height: 100%;
      z-index: -1;
    }
  }
  .footer1__groupSite1__title1 {
    @include min-sm {
      font-size: 17px;
      color: #fff;
    }
  }
  .footer1__groupSite1__subInner1 {
    @include min-sm {
      display: flex;
      margin: 20px 0 0 0;
      width: 100%;
    }
  }
  .footer1__groupSite1__unit1 {
    min-width: 340px;
    max-width: 340px;
    border-left: 1px dashed #fcd3ac;
    padding: 0 15px;
    box-sizing: border-box;
    &:first-child {
      @include min-sm {
        max-width: 400px;
        min-width: 400px;
        padding-left: 0;
        border: none;
      }
    }
  }
  .footer1__groupSite1__title2 {
    @include min-sm {
      color: #fedab8;
      font-size: 15px;
    }
  }
  .footer1__groupSite1__copyright1 {
    @include min-sm {
      position: absolute;
      right: 0;
      bottom: 20px;
      font-size: 10px;
      color: #fff;
    }
  }
  .footer1__btnTop1 {
    a {
      @include min-sm {
        display: block;
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 80px;
        height: 80px;
        border: 1px solid #fff;
        line-height: 80px;
        text-align: center;
        background: #fbc088;
        z-index: 10;
      }
      &:hover {
        opacity: 1;
        background: darken(#fbc088,10);
      }
    }
  }
  .footer1__image1 {
    margin: -90px 0 0 0;
    @include min-sm {
      background: url('../images/img_footer1_1.jpg') center center no-repeat;
      background-size: cover;
      height: 230px;
      padding: 135px 0 0 0;
    }
  }
  .footer1__image1__logo1 {
    @include min-sm {
      text-align: center;
    }
  }
  .title3 {
    @include min-sm {
      margin: 0 0 10px 0;
    }
  }
  .footer1__btnTop2 {
    text-align: center;
    @include min-sm {
      display: none;
    }
    img {
      width: 27px;
      height: auto;
    }
  }
}

/* ------------------------------------------------- *
/*        lnav1
/* ------------------------------------------------- */

.lnav1 {
  display: none;
  @include min-sm {
    display: block;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .lnav1__lists1 {
    a {
      word-break: break-all;
      &.-active {
        background: #fbc088;
        color: #fff;
        @at-root {
          .lnav1.-type1 .lnav1__lists1 a.-active {
            background: #fbc088;
            color: #fff;
          }
        }
      }
    }
    &>li {
      border-bottom: 1px solid #fbc088;
      @at-root {
        .lnav1.-type1 .lnav1__lists1>li {
          border-color: #fbc088;
        }
      }
      &:first-child {
        border-top: 1px solid #fbc088;
        @at-root {
          .lnav1.-type1 .lnav1__lists1>li:first-child {
            border-color: #fbc088;
          }
        }
      }
      &>span,
      &>a {
        cursor: pointer;
        display: block;
        font-size: 17px;
        color: #fbc088;
        font-weight: bold;
        padding: 20px 50px 20px 30px;
        position: relative;
        @at-root {
          .lnav1.-type1 .lnav1__lists1>li>span ,
          .lnav1.-type1 .lnav1__lists1>li>a {
            color: #fbc088;
          }
        }
        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-top: 2px solid #fbc088;
          border-right: 2px solid #fbc088;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
          transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
          @at-root {
            .lnav1.-type1 .lnav1__lists1>li>span ,
            .lnav1.-type1 .lnav1__lists1>li>a {
              &:before {
                border-color: #fbc088;
              }
            }
            .lnav1.-type1 .lnav1__lists1>li>a {
              &:before {
                border-color: #fbc088;
              }
            }
            .lnav1__lists1>li>a ,
            .lnav1__lists1>li>span {
              &:hover {
                &:before {
                  border-color: #fff;
                }
              }
            }
          }
        }
        &:hover {
          opacity: 1;
          background: #fbc088;
          color: #fff;
          @at-root {
            .lnav1.-type1 .lnav1__lists1>li>span ,
            .lnav1.-type1 .lnav1__lists1>li>a {
              &:hover {
                background: #fbc088;
              }
            }
          }
        }
      }
      span {
        &:hover ,
        &.-active {
          background: #fbc088;
          color: #fff;
          @at-root {
            .lnav1.-type1 .lnav1__lists1>li>span {
              &.-active,
              &:hover {
                background: #fbc088;
              }
            }
          }
          &:before {
            transform: rotate(135deg);
            border-color: #fff;
          }
        }
      }
      &>div {
        display: none;
        &.-active {
          display: block;
        }
        &>ul {
          overflow: hidden;
          background: none;
          &>li {
            margin: 5px 20px 0;
            &:first-child {
              margin-top: 30px;
            }
            &:last-child {
              margin-bottom: 30px;
            }
            &>a {
              display: block;
              position: relative;
              color: #fbc088;
              padding: 3px 10px 3px 25px;
              line-height: 1.2;
              @at-root {
                .lnav1.-type1 .lnav1__lists1>li>div>ul>li>a {
                  color: #fbc088;
                }
              }
              &:before {
                width: 7px;
                height: 7px;
                content: '';
                display: block;
                border-top: 1px solid #fbc088;
                border-right: 1px solid #fbc088;
                transform: rotate(45deg);
                position: absolute;
                top: 9px;
                left: 5px;
                @at-root {
                  .lnav1.-type1 .lnav1__lists1>li>div>ul>li>a {
                    &:before {
                      border-color: #fbc088;
                    }
                  }
                  .lnav1 .lnav1__lists1>li>div>ul>li>a.-active {
                    &:before {
                      border-color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .lnav1__bannerLists1 {
    list-style: none;
    margin: 40px 0 0 0;
    padding: 0;
    li {
      margin: 30px 0 0 0;
    }
  }
}

/* ------------------------------------------------- *
/*        lnav2
/* ------------------------------------------------- */

.lnav2 {
  .widget {
    margin: 40px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
    h3 {
      color: #fff;
      background: #fbc088;
      padding: 10px 20px;
      line-height: 1;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 20px;
      ul {
        list-style: none;
        margin: 0 0 0 20px;
        padding: 0;
      }
      div {
        display: none;
      }
      span,
      a {
        color: #FBC088;
        font-size: 15px;
      }
    }
  }
  .lnav2__button1 {
    margin: 20px 0 0 0;
    a {
      width: 150px;
      margin: auto;
      display: block;
      background: #fff;
      font-size: 15px;
      border: 1px solid #FBC088;
      line-height: 1;
      padding: 12px 20px;
      color: #fbc088;
      text-align: center;
    }
  }
  .calendar_wrap {
    padding: 0 10px;
    table {
      width: 230px;
      text-align: center;
      line-height: 1;
      margin: 15px auto 0;
      @include min-sm {
        font-size: 13px;
      }
      caption {
        color: #FBC088;
        text-align: center;
        font-weight: bold;
        margin: 0 0 10px 0;
      }
      th,
      td {
        padding: 5px;
      }
      thead {
        th {
          padding: 10px 5px;
        }
      }
      tfoot {
        td {
          padding: 15px 0 0 0;
        }
        a {
          color: #0D4FED;
          text-decoration: underline;
        }
      }
      tbody {
        tr {
          border-top: 1px solid #FBC088;
        }
        td {
          color: #FBC088;
          height: 32px;
          &#today {
            vertical-align: middle;
            border-radius: 200%;
            @include min-sm {
            }
          }
          &#today {
            background: #FBC088;
            color: #fff;
          }
        }
      }
    }
  }
  .lnav2__calendar1__header1 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #FBC088;
    position: relative;
    margin: 20px 0 0 0;
    @include min-sm {
      font-size: 14px;
    }
    ul {
      position: absolute;
      left: 0;
      top: 3px;
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 30px;
        a {
          display: block;
          width: 20px;
          height: 20px;
          position: relative;
          border-bottom: 1px solid #0d4fed;
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 1px solid #0d4fed;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            @include min-sm {
              width: 6px;
              height: 6px;
            }
          }
        }
        &:first-child {
          a {
            &:before {
              border-left: 1px solid #0d4fed;
              transform: rotate(-45deg);
            }
          }
        }
        &:last-child {
          a {
            &:before {
              border-right: 1px solid #0d4fed;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------- *
 *        wysiwyg1
 * ------------------------------------------------- */

.wysiwyg1 {
  font-size: 14px;
  line-height: 1.8;
  margin: 30px 10px 0;
  word-wrap: break-word;
  h2 {
    background: #FCE4D5;
    padding: 15px 10px 15px 15px;
    height: 100%;
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
    color: #333;
    margin: 50px 0 0 0;
    @at-root {
      .wysiwyg1.-type1 h2 {
        color: #333;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    @include min-sm {
      font-size: 28px;
    }
    span {
      border-left: 5px solid #fbc088;
      padding: 0 0 0 10px;
      @at-root {
        .wysiwyg1.-type1 h2 span {
          border-color: #fbc088;
        }
      }
    }
  }
  h3 {
    margin: 50px 0 0;
    font-size: 22px;
    line-height: 1.2;
    position: relative;
    border-left: 5px solid #fbc088;
    padding: 0 0 0 10px;
    @include min-sm {
      font-size: 26px;
    }
    @at-root {
      .wysiwyg1.-type1 h3 {
        border-color: #fbc088;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      position: absolute;
      top: 12px;
      left: 4px;
    }
  }
  h4 {
    margin: 50px 0 0;
    padding: 5px 0 5px 20px;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    clear: both;
    @include min-sm {
      font-size: 24px;
      padding-left: 30px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '■';
      position: absolute;
      left: 0;
      top: 10px;
      color: #fbc088;
      font-size: 16px;
      @include min-sm {
        top: 13px;
      }
      @at-root {
        .wysiwyg1.-type1 h4:before {
          color: #fbc088;
        }
      }
    }
  }
  a {
    position: relative;
    color: #0D4FED;
    text-decoration: underline;
  }
  p {
    margin: 20px 0 0 0;
    text-align: justify;
    @at-root {
      .wysiwyg1 {
        br {
          &.-clearFix {
            & + .wp-caption {
              margin-top: 0;
              & + p {
                margin-top: 0;
              }
            }
            & + p {
              margin-top: 0;
            }
          }
        }
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    &.alignleft {
      float: left;
      margin: 0 40px 20px 0;
      @include max-sm {
        float: none;
        display: table;
        margin: 0 auto 20px;
      }
    }
    &.alignright {
      float: right;
      margin: 0 0 20px 40px;
      @include max-sm {
        float: none;
        display: table;
        margin: 0 auto 20px;
      }
    }
    &.aligncenter {
      display: block;
      margin: auto;
      margin: 0 auto 20px;
      @include max-sm {
        float: none;
        display: table;
        margin: 0 auto 20px;
      }
    }
  }
  ol,
  ul {
    margin: 40px 0 0 25px;
    @at-root {
      .wysiwyg1 h4+ol ,
      .wysiwyg1 h3+ol ,
      .wysiwyg1 h2+ol ,
      .wysiwyg1 h4+ul ,
      .wysiwyg1 h3+ul ,
      .wysiwyg1 h2+ul {
        margin-top: 20px;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    li {
      margin-top: 5px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul {
    &.is-iconNone {
      list-style: none;
      padding-left: 25px;
    }
  }
  dl {
    margin: 40px 0 0 10px;
    @at-root {
      .wysiwyg1 h4+dl ,
      .wysiwyg1 h3+dl ,
      .wysiwyg1 h2+dl {
        margin-top: 20px;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    dt {
      position: relative;
      padding-left: 20px;
      margin: 20px 0 0;
      &:first-child {
        margin-top: 0;
      }
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: '■';
        display: block;
      }
    }
    dd {
      margin-top: 5px;
    }
  }
  .table-responsive {
    margin: 20px 0 0;
    th,td {
      border-left: 1px solid #eee;
      &:first-child {
        border-left: none;
      }
    }
  }
  table {
    width: 100%;
    margin: 40px 0 0;
    @at-root {
      .wysiwyg1 h4+table ,
      .wysiwyg1 h3+table ,
      .wysiwyg1 h2+table {
        margin-top: 20px;
      }
    }
    @include max-sm {
      border: none;
      border-bottom: 1px solid #eee;
    }
    &:first-child {
      margin-top: 0;
    }
    tbody {
      width: 100%;
    }
    tr {
      border-top: 1px solid #eee;
      &:first-child {
        border: none;
      }
    }
    th,td {
      padding: 10px 15px;
      @include max-sm {
        border-left: 1px solid #eee;
        &:first-child {
          border-left: none;
        }
      }
    }
    th {
      background: #fff;
      font-weight: normal;
      padding: 10px 30px;
      @include max-sm {
        min-width: 150px;
        display: block;
        border: none;
        border-top: 2px solid #eee;
      }
    }
    td {
      @include max-sm {
        display: block;
        border: none;
        border-top: 1px dotted #eee;
      }
    }
  }
  br {
    font-size: 1px;
    &.-clearFix {
      clear: both;
    }
  }
  .wp-caption {
    &.alignleft {
      float: left;
      margin: 20px 40px 20px 0;
      @include max-sm {
        float: none;
        display: table;
        margin: 20px auto 20px;
      }
    }
    &.alignright {
      float: right;
      margin: 20px 0 20px 40px;
      @include max-sm {
        float: none;
        display: table;
        margin: 20px auto 20px;
      }
    }
    &.aligncenter {
      display: table;
      margin: 20px auto 20px;
    }
    @at-root {
      .wysiwyg1 {
        br {
          &.-clearFix {
            & + .wp-caption {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .wp-caption-text {
    color: #666;
    font-size: 12px;
    margin: 5px 0 -2px 0;
    &:before {
      content: '■';
    }
  }
  .button1 {
    width: auto;
    text-decoration: none;
    display: inline-block;
    margin: 15px 20px 0 0;
    @include max-sm {
      width: 100%;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        kv1
/* ------------------------------------------------- */

.kv1 {
  position: relative;
  padding: 100% 0 0 0;
  background: url('../images/img_kv1_1.jpg') center 0 no-repeat;
  background-size: cover;
  @include min-sm {
    height: 435px;
    padding: 0;
    overflow: hidden;
    background: url('../images/img_kv1_2.jpg') center center no-repeat;
    background-size: cover;
    min-width: 1100px;
  }
  .kv1__catch1 {
    position: absolute;
    right: 35px;
    top: 35px;
    width: 26.8%;
    display: none;
    @include min-sm {
      display: block;
      position: relative;
      right: 0;
      top: 0;
      min-width: 1100px;
      height: 435px;
      margin: auto;
    }
    &:before {
      @include min-sm {
        content: '';
        display: block;
        position: absolute;
        top: 45px;
        right: 160px;
        margin: auto;
        width: 254px;
        height: 298px;
        background: url('/assets/images/text_kv1_2.png') 0 0 no-repeat;
      }
    }
    img {
      width: 100%;
      height: auto;
      @include min-sm {
        display: none;
      }
    }
  }
  .kv1__contact1 {
    position: fixed;
    top: 155px;
    right: -300px;
    height: 240px;
    display: none;
    transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 100;
    @include min-sm {
      display: flex;
    }
    &.-active {
      @include min-sm {
        right: 0;
      }
    }
  }
  .kv1__contact1__title1 {
    background: #2DB0C2;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 40px;
    position: relative;
    padding: 0 5px 0 15px;
    letter-spacing: 0.1em;
    line-height: 1.4;
    display: flex;
    align-items: center;
    border-radius: 5px 0px 0px 5px;
    writing-mode: vertical-rl;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      margin: auto;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left: 7px solid #fff;
    }
  }
  .kv1__contact1__body1 {
    background: rgba(#FFF,.88);
    padding: 10px;
    width: 300px;
  }
  .kv1__contact1__lists1 {
    line-height: 1;
    dt {
      font-size: 14px;
      font-weight: bold;
      background: #2DB0C2;
      color: #fff;
      text-align: center;
      padding: 6px 10px;
      margin: 12px 0 10px 0;
      &:first-child {
        margin-top: 0;
      }
    }
    dd {
      font-size: 14px;
      letter-spacing: -0.05em;
      margin: 5px 0 0 0;
      color: #000;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        text-align: center;
      }
      span {
        color: #2DB0C2;
        display: inline-block;
        vertical-align: middle;
        width: 2em;
        margin: 0 10px 0 0;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        pageNav1
/* ------------------------------------------------- */

.pageNav1 {
  margin: 40px 0 0 0;
  .pageNav1__lists {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      width: 90px;
      margin: 0 12px;
      &:first-child {
        a {
          @extend .button8;
          @extend .-type1;
        }
      }
      &:last-child {
        a {
          @extend .button8;
          @extend .-type2;
        }
      }
    }
  }
}

/* ------------------------------------------------- *
/*        wp-pagenavi
/* ------------------------------------------------- */

.wp-pagenavi {
  position: relative;
  width: 278px;
  margin: 40px auto 0;
  height: 90px;
  text-align: center;
  @include min-sm {
    width: auto;
    height: auto;
    margin: 50px 0 0 0;
  }
  span,
  a {
    display: inline-block;
    vertical-align: top;
    background: #fff;
    color: #fbc088;
    border: 1px solid #fbc088;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    margin: 0 3px;
    transition: all .1s linear;
    &:hover {
      background: #fbc088;
      color: #fff;
      opacity: 1;
    }
  }
  span {
    background: #fbc088;
    color: #fff;
  }
  .extend {
    display: none;
  }
  .last,
  .first {
    width: auto;
    position: absolute;
    bottom: 0;
    padding: 0 10px;
    @include min-sm {
      position: static;
    }
  }
  .first {
    left: 0;
  }
  .last {
    right: 0;
  }
  .nextpostslink,
  .previouspostslink {
    width: auto;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

/* ------------------------------------------------- *
/*        mw_wp_form
/* ------------------------------------------------- */

.mw_wp_form {
  .mwform-checkbox-field,
  .mwform-radio-field {
    input {
      min-height: inherit;
    }
    margin: 0 0 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .mwform-tel-field {
    input[type=text] {
      width: auto;
    }
  }
  table.tbl_pt01 {
    margin: 0 0 30px 0;
    border: 1px solid #333;
    th {
      border-top: none;
      @include min-sm {
        white-space: nowrap;
      }
      span {
        display: inline-block;
        margin: 0 0 0 5px;
      }
    }
    th,td {
      @include min-sm {
        border: 1px solid #333;
      }
    }
  }
  table {
    tbody {
      th {
        color: #fff;
        background: #fbc088;
        font-weight: bold;
        @include min-sm {
          min-width: 180px;
        }
      }
      td {
        padding: 20px;
      }
    }
  }
  textarea,
  input[type=email],
  input[type=text] {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 16px;
    width: 100%;
    @include min-sm {
    }
  }
  textarea {
    width: 100%;
  }
  button[type=submit] {
    background: #fbc088;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin: auto 0;
    width: 100%;
    padding: 15px 0;
    border-radius: 4px;
    @include min-sm {
      width: 300px;
      margin: 30px auto 0;
    }
  }
}

/* ------------------------------------------------- *
/*        mw_wp_form
/* ------------------------------------------------- */

.mw_wp_form {
  .mwform-radio-field {
    margin: 0 0 0 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .mwform-tel-field {
    input[type=text] {
      width: auto;
    }
  }
  .tbl_pt01 {
    margin: 0 0 30px 0;
  }
  table {
    tbody {
      th {
        color: #fff;
        background: #fbc088;
        font-weight: bold;
        @include min-sm {
          min-width: 180px;
        }
      }
      td {
        padding: 20px;
      }
    }
  }
  textarea,
  input[type=email],
  input[type=text] {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 16px;
    width: 100%;
    @include min-sm {
    }
  }
  textarea {
    width: 100%;
  }
  button[type=submit] {
    background: #fbc088;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin: auto 0;
    width: 100%;
    padding: 15px 0;
    border-radius: 4px;
    @include min-sm {
      width: 300px;
      margin: 30px auto 0;
    }
  }
}

/* ------------------------------------------------- *
/*        block1
/* ------------------------------------------------- */

.block1 {
  margin: 40px 20px 0;
  @include min-sm {
    max-width: 1000px;
    margin: 80px auto 0;
  }
  .block1__inner1 {
    margin: 50px 0 0 0;
  }
  .block1__left1 {
    @include min-sm {
      width: 652px;
    }
  }
  .block1__right1 {
    display: none;
    @include min-sm {
      position: relative;
      display: block;
      width: 304px;
    }
  }
  .title1  {
    @include min-sm {
      margin: 0 0 30px 0;
    }
  }
  .textLinkGroup1 {
    margin: 12px 0 0 0;
    @include min-sm {
      margin: 0;
    }
  }
  .textLink2 {
    @include min-sm {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        block2
/* ------------------------------------------------- */

.block2 {
  margin: 60px 0 0 0;
  section {
    margin: 0 0 -1px 0;
    @include min-sm {
      margin: 80px 0 0 0;
      &:first-child {
        margin: 0;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        block3
/* ------------------------------------------------- */

.block3 {
  margin: 20px 0 0 0;
  @include min-sm {
    max-width: 1000px;
    margin: 120px auto 0;
  }
}

/* ------------------------------------------------- *
/*        block4
/* ------------------------------------------------- */

.block4 {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 20px  0 0 0;
  width: 100%;
  z-index: 1000;
  @include min-sm {
    display: none;
  }
}

/* ------------------------------------------------- *
/*        block5
/* ------------------------------------------------- */

.block5 {
  display: none;
  @include min-sm {
    display: block;
    max-width: 1000px;
    margin: 40px auto 0;
  }
}

/* ------------------------------------------------- *
/*        block6
/* ------------------------------------------------- */

.block6 {
  position: relative;
  @include min-sm {
    padding: 0 0 0 260px;
  }
  .block6__image1 {
    text-align: center;
    @include min-sm {
      margin: 40px 0 0 0;
      text-align: left;
    }
  }
  .block6__image1__image {
    text-align: center;
    margin: 25px 0 0 0;
    @include min-sm {
      position: absolute;
      top: 0;
      left: 0;
      width: 240px;
      margin: 0;
    }
    img {
      @include min-sm {
        width: 100%;
        height: auto;
      }
    }
  }
  .block6__image1__text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 0 0;
    color: #000;
    @include min-sm {
      margin: 0;
    }
    span {
      display: inline-block;
      color: #fff;
      text-align: center;
      background: #2DB0C2;
      padding: 5px 10px;
      line-height: 1;
      margin: 0 0 5px 0;
      @include min-sm {
        text-align: left;
      }
    }
  }
  .block6__lists1 {
    font-size: 14px;
    margin: 45px 10px 0;
    text-align: center;
    @include min-sm {
      margin: 20px 0 0 0;
    }
    dt {
      font-weight: bold;
      background: #F0F0F0;
      text-align: center;
      padding: 5px;
      color: #2DB0C2;
      margin: 20px 0 0 0;
      @include min-sm {
        text-align: left;
        padding: 5px 10px;
        margin: 35px 0 0 0;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    dd {
      text-align: left;
      margin: 20px 0 0 0;
      @include min-sm {
        margin: 15px 0 0 0;
      }
      &>a {
        color: #0D4FED;
        text-decoration: underline;
      }
    }
  }
  .block6__btnBack1 {
    text-align: center;
    margin: 40px 0 0 0;
    @include min-sm {
      display: none;
    }
  }
}

/* ------------------------------------------------- *
/*        block7
/* ------------------------------------------------- */

.block7 {
  margin: 40px 10px 0;
  @include min-sm {
    margin: 40px 0 0 0;
  }
  &:first-child {
    margin-top: 25px;
    @include min-sm {
      margin: 0;
    }
  }
}

/* ------------------------------------------------- *
/*        block8
/* ------------------------------------------------- */

.block8 {
  margin: 25px 0 0 0;
}

/* ------------------------------------------------- *
/*        block9
/* ------------------------------------------------- */

.block9 {
  font-size: 15px;
  margin: 20px 10px 0 10px;
  @include min-sm {
    margin: 40px 0 0 0;
  }
  .block9__text1 {
    margin: 5px 0 0 0;
    strong {
      color: #F00;
      font-weight: bold;
    }
  }
  .block9__title1 {
    margin: 20px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  .title5 {
    margin: 0 0 10px 0;
  }
}

/* ------------------------------------------------- *
/*        block10
/* ------------------------------------------------- */

.block10 {
  margin: 30px 10px 0;
  @include min-sm {
    margin: 40px 0 0 0;
  }
  .formGroup1 {
    margin: 10px 0 0 0;
    @include min-sm {
      margin-top: 20px;
    }
  }
  .table1 {
    margin: 25px 0 0 0;
    @include min-sm {
      margin: 30px 0 0 0;
    }
  }
  .table1__today1 {
    margin: 25px 0 0 0;
  }
  .table1__table2 {
    margin: 25px 0 0 0;
  }
  .table1__table3 {
    margin: 25px 0 0 0;
  }
}

/* ------------------------------------------------- *
/*        block11
/* ------------------------------------------------- */

.block11 {
  margin: 40px 0 0 0;
  padding: 50px 20px 20px 20px;
  background: #fff;
  @include min-sm {
    padding: 0;
    margin: 80px 0 0 0;
  }
  .block11__inner1 {
    @include min-sm {
      max-width: 1000px;
      margin: auto;
      display: flex;
      align-items: center;
    }
  }
  .block11__body1 {
    @include min-sm {
      min-width: 365px;
      padding: 0 70px 0 0;
    }
  }
  .block11__text1 {
    font-size: 16px;
    margin: 15px 0 0 0;
    line-height: 1.4;
  }
  .block11__lists {
    list-style: none;
    margin: 25px 0 0 0;
    padding: 0;
    li {
      margin: 5px 0 0 0;
      &:first-child {
        margin-top: 0;
      }
      span {
        width: 45px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 0;
        color: #fff;
        text-align: center;
        background: #3F3929;
        border-radius: 3px;
      }
    }
  }
  .block11__map {
    margin: 20px 0 0 0;
    @include min-sm {
      width: 100%;
      margin: 0;
    }
    iframe {
      width: 100%;
      height: 192px;
      @include min-sm {
        height: 260px;
      }
    }
  }
}

/* ------------------------------------------------- *
/*        block12
/* ------------------------------------------------- */

.block12 {
  padding: 30px;
  font-size: 14px;
  @include min-sm {
    text-align: center;
  }
  .block12__link1 {
    margin: 30px 0 0 0;
    a {
      text-decoration: underline;
    }
  }
}

/* ================================================== *
/*
/*    MEDIA QUERY
/*
/* ================================================== */
