// breakPoint

$screen-phone      : 480;
$screen-tablet     : 768;
$screen-desktop    : 992;
$screen-lg-desktop : 1200;

@mixin min-xs {
  @media screen and (min-width: $screen-phone + px) { @content; }
}
@mixin min-sm {
  @media screen and (min-width: $screen-tablet + px) { @content; }
}
@mixin min-md {
  @media screen and (min-width: $screen-desktop + px) { @content; }
}
@mixin min-lg {
  @media screen and (min-width: $screen-lg-desktop + px) { @content; }
}

@mixin max-xs {
  @media screen and (max-width: ($screen-phone)-1px) { @content; }
}
@mixin max-sm {
  @media screen and (max-width: ($screen-tablet)-1px) { @content; }
}
@mixin max-md {
  @media screen and (max-width: ($screen-desktop)-1px) { @content; }
}
@mixin max-lg {
  @media screen and (max-width: ($screen-lg-desktop)-1px) { @content; }
}

// 複数行3点リーダー

@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}